import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private localStorageService: LocalStorageService,
    private route: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          this.route.navigate(['../login'])
          //  auto refreshtoken if 401 response returned from api
          this.authService.refreshToken().subscribe(res => {
            if (res.success) {
              if (res.data) {
                this.localStorageService.set('accessToken', res.access_token);
                this.localStorageService.set('currentUser', res.data);
                window.location.reload();
              }
            }
          })
        } else {
          return throwError(err);
        }
      }))
  }
}
