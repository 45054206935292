import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../services/user/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin/dashboard/management',
  },
  {
    path: 'login',
    loadChildren: 'app/pages/auth/auth.module#AuthModule',
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/auth/auth.module#AuthModule',
  },
  {
    path: 'admin/dashboard',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule',
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/settings/settings.module#SettingsModule',
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/admin/admin.module#AdminModule',
  },


];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
