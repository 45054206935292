import {Injectable} from '@angular/core';
import {environment} from "../environments/environment.prod";
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable()
export class LanguageService {
    lang: string;

    constructor(
        private translateService: TranslateService,
        private localStorageService: LocalStorageService
    ) {
        this.lang = this.localStorageService.get("lang") as string || "ar";
    }

    setLanguage(lang: string) {
        this.localStorageService.set("lang", lang.toLowerCase());
        this.translateService.use(lang);
        window.location.reload();
    }
    isLang(lang: string): boolean {
        return this.lang === lang;
    }
    name(obj: any) {
        return this.isLang('ar') ? obj['NameArabic'] : obj['NameEnglish'];
    }
    getLanguageOrDefault() {
        if (this.lang) {
            return this.lang;
        } else {
            return environment.defaultLanguage;
        }
    }
}
