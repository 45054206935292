import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbLayoutDirection,
  NbLayoutDirectionService
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService, StateService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '../../../../services/language.service';
import { AuthService } from '../../../../services/user/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentTheme = 'default';

  userMenu = [
    // {
    //     title: (this.languageService.getLanguageOrDefault() == "ar") ? 'تعديل الملف الشخصى' : 'Profile',
    //     icon: 'person-outline',
    //     link: '/admin/profile/'
    // },
    {
      title: this.languageService.getLanguageOrDefault() == "ar" ? 'تسجيل الخروج' : 'Change Password',
      icon: 'unlock-outline',
      link: '/admin/change-password'
    },
    {
      title: this.languageService.getLanguageOrDefault() == "ar" ? 'تغيير كلمة المرور' : 'Log out',
      icon: 'log-out-outline',
      link: '/login'
    }
  ];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private directionService: NbLayoutDirectionService,
    private themeService: NbThemeService,
    private stateService: StateService,
    public languageService: LanguageService,
    private userService: UserData,
    private layoutService: LayoutService,
    public authService: AuthService,
    private modalService: BsModalService,
    private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser
    this.currentTheme = this.themeService.currentTheme;
    if (this.languageService.getLanguageOrDefault() == "ar") {
      this.directionService.setDirection(NbLayoutDirection.RTL)
    }
    else {
      this.directionService.setDirection(NbLayoutDirection.LTR)
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  translate(lang) {
    if (lang == "LTR") {
      this.languageService.setLanguage('ar')
      this.directionService.setDirection(NbLayoutDirection.RTL)
    }
    else {
      this.languageService.setLanguage('en')
      this.directionService.setDirection(NbLayoutDirection.LTR)
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
