import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <!--Created  by <b><a href="https://start-it.online/" target="_blank">Start-it</a></b> {{date}}-->
    </span>
  `,
})
export class FooterComponent {
  date = new Date().getFullYear();
}
