import { Injectable, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { tap, flatMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { environment } from '../environments/environment.prod';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class WebApiService {
  constructor(
    private http: HttpClient,
    private languageService:LanguageService,
    private localStorageService: LocalStorageService
  ) { }

  private async setHeaders(file = false): Promise<HttpHeaders> {
    let headersConfig;
    if (file) {
      headersConfig = {}
    } else {
      headersConfig = {
        'Content-Type': 'application/json',
        // Connection: 'Keep-Alive',
        'X-localization': this.languageService.getLanguageOrDefault(),
        Accept: 'application/json',
        'X-Requested-With': 'application/XMLHttpRequest',
        Authorization: String('Bearer ' + this.localStorageService.get('accessToken') || ''),
      };
    }
    return await new HttpHeaders(headersConfig);
  }
  private logError(path: string, error: any) {
    if (!environment.production) console.log(path, error);
  }
  private log(path, res) {
    if (!environment.production) console.log(path, res);
  }
  get(path: string, params?: HttpParams): Observable<ResultViewModel> {
    // console.log(`${environment.api_url}${path}`)
    return from(this.setHeaders()).pipe(flatMap((headers: HttpHeaders) =>
      this.http.get<ResultViewModel>(`${environment.api_url}${path}`, { headers: headers, params }).pipe(tap(res => this.log(path, res), error => this.logError(path, error)))
    ))
  }
  post(path: string, body: Object = {}): Observable<ResultViewModel> {
    return from(this.setHeaders()).pipe(flatMap((headers: HttpHeaders) =>
      this.http.post<ResultViewModel>(`${environment.api_url}${path}`, body, { headers: headers }).pipe(tap(res => this.log(path, res), error => this.logError(path, error)))
    ))
  }
  put(path: string, body: Object = {}): Observable<ResultViewModel> {
    return from(this.setHeaders()).pipe(flatMap((headers: HttpHeaders) =>
      this.http.put<ResultViewModel>(`${environment.api_url}${path}`, body, { headers: headers }).pipe(tap(res => this.log(path, res), error => this.logError(path, error)))
    ))
  }
  delete(path: string, params?: HttpParams): Observable<ResultViewModel> {
    return from(this.setHeaders()).pipe(flatMap((headers: HttpHeaders) =>
      this.http.delete<ResultViewModel>(`${environment.api_url}${path}`, { headers: headers, params },  )
    ))
  }

  postFile(path: string, body: Object = {}): Observable<ResultViewModel> {
    return from(this.setHeaders(true)).pipe(flatMap((headers: HttpHeaders) =>
      this.http.post<ResultViewModel>(`${environment.api_url}${path}`, body, { headers: headers }).pipe(tap(res => this.log(path, res), error => this.logError(path, error)))
    ))
  }
}

export interface ResultViewModel {
  data:any;
  permissions: any;
  success: boolean;
  message: string;
  isAuthorized: boolean;
  access_token:string
}